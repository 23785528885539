import React from "react";

const RadioButton = ({ label, checked, setChecked }) => {
  return (
    <div className='flex'>
      <input
        type='radio'
        checked={checked}
        className='text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 h-full align-middle'
        onChange={setChecked}
      />
      <label
        className='ml-1 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300 h-full align-middle hover:cursor-pointer'
        onClick={setChecked}
      >
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
