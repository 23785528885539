import React, { useEffect, useState } from "react";

import Map from "./components/Map.js";
import Header from "./components/Header.js";
import Sidebar from "./components/Sidebar.js";

import hexagonJson from "./data/hexagons.json";
import "./App.css";

const HOOD_COUNT = 263;
const HEX_COUNT = 11675;

function App() {
  const [hexScoresRaw, setHexScoresRaw] = useState([]);
  const [hexScores, setHexScores] = useState([]);
  const [hoodScores, setHoodScores] = useState([]);
  const [hexScoresFinal, setHexScoresFinal] = useState(
    Array(HEX_COUNT).fill(0)
  );
  const [hoodScoresFinal, setHoodScoresFinal] = useState(
    Array(HOOD_COUNT).fill(0)
  );

  const [layer, setLayer] = useState("hex");

  const [filters, setFilters] = useState({
    citiBikes: 0,
    budget: 0,
    transit: 0,
    transit123: 0,
    transit456: 0,
  });

  // RAW SCORES

  useEffect(() => {
    setHexScoresRaw(hexagonJson);
  }, []);

  // SCORES

  const timeToScore = (time) => {
    return 100 / (1 + (0.05 * Math.max(time ?? 100000, 1)) ** 2);
  };

  useEffect(() => {
    if (hexScoresRaw.length === 0) return;

    const hexScores = Array(HEX_COUNT)
      .fill(0)
      .map((_, i) => {
        const hex = hexScoresRaw[i];
        return {
          neighborhood: hex.neighborhood,
          citiBikeDistance: timeToScore(hex?.citiBikeDistance),
          transit123: timeToScore(hex?.transit123),
          transit456: timeToScore(hex?.transit456),
          transit7: timeToScore(hex?.transit7),
          transitACE: timeToScore(hex?.transitACE),
          transitBDFM: timeToScore(hex?.transitBDFM),
          transitG: timeToScore(hex?.transitG),
          transitJZ: timeToScore(hex?.transitJZ),
          transitL: timeToScore(hex?.transitL),
          transitNQRW: timeToScore(hex?.transitNQRW),
          transitSIR: timeToScore(hex?.transitSIR),
          transit: timeToScore(hex?.transit),
          income: 100 - Math.min((hex?.income ?? 0) / 1500, 100),
        };
      });

    setHexScores(hexScores);
  }, [hexScoresRaw]);

  // CONVERT TO HOOD SCORES

  useEffect(() => {
    if (hexScores.length === 0) return;

    const hoodScores = Array(HOOD_COUNT)
      .fill(0)
      .map((_, i) => ({
        citiBikeDistance: 0,
        transit123: 0,
        transit456: 0,
        transit7: 0,
        transitACE: 0,
        transitBDFM: 0,
        transitG: 0,
        transitJZ: 0,
        transitL: 0,
        transitNQRW: 0,
        transitSIR: 0,
        transit: 0,
        income: 0,
        hexes: 0,
      }));

    hexScores.forEach((hex) => {
      const hood = hex["neighborhood"];
      hoodScores[hood].citiBikeDistance += hex?.citiBikeDistance ?? 0;
      hoodScores[hood].transit123 += hex?.transit123 ?? 0;
      hoodScores[hood].transit456 += hex?.transit456 ?? 0;
      hoodScores[hood].transit7 += hex?.transit7 ?? 0;
      hoodScores[hood].transitACE += hex?.transitACE ?? 0;
      hoodScores[hood].transitBDFM += hex?.transitBDFM ?? 0;
      hoodScores[hood].transitG += hex?.transitG ?? 0;
      hoodScores[hood].transitJZ += hex?.transitJZ ?? 0;
      hoodScores[hood].transitL += hex?.transitL ?? 0;
      hoodScores[hood].transitNQRW += hex?.transitNQRW ?? 0;
      hoodScores[hood].transitSIR += hex?.transitSIR ?? 0;
      hoodScores[hood].transit += hex?.transit ?? 0;
      hoodScores[hood].income += hex?.income ?? 0;
      hoodScores[hood].hexes++;
    });

    hoodScores.forEach((hood) => {
      const hexes = hood.hexes;
      hood.citiBikeDistance = hexes > 0 ? hood.citiBikeDistance / hexes : 0;
      hood.transit123 = hexes > 0 ? hood.transit123 / hexes : 0;
      hood.transit456 = hexes > 0 ? hood.transit456 / hexes : 0;
      hood.transit7 = hexes > 0 ? hood.transit7 / hexes : 0;
      hood.transitACE = hexes > 0 ? hood.transitACE / hexes : 0;
      hood.transitBDFM = hexes > 0 ? hood.transitBDFM / hexes : 0;
      hood.transitG = hexes > 0 ? hood.transitG / hexes : 0;
      hood.transitJZ = hexes > 0 ? hood.transitJZ / hexes : 0;
      hood.transitL = hexes > 0 ? hood.transitL / hexes : 0;
      hood.transitNQRW = hexes > 0 ? hood.transitNQRW / hexes : 0;
      hood.transitSIR = hexes > 0 ? hood.transitSIR / hexes : 0;
      hood.transit = hexes > 0 ? hood.transit / hexes : 0;
      hood.income = hexes > 0 ? hood.income / hexes : 0;
    });

    setHoodScores(hoodScores);
  }, [hexScores]);

  // FINAL SCORES

  useEffect(() => {
    const applyFilter = (tile) => {
      return (
        (filters.citiBikes * tile.citiBikeDistance +
          filters.budget * tile.income +
          filters.transit * tile.transit +
          filters.transit123 * tile.transit123 +
          filters.transit456 * tile.transit456) /
        Math.max(
          1,
          filters.citiBikes +
            filters.budget +
            filters.transit +
            filters.transit123 +
            filters.transit456
        )
      );
    };

    const rescale = (tile, max) => {
      return Math.round((tile / Math.max(1, max)) * 100);
    };

    if (hexScores.length === 0 || hoodScores.length === 0) return;
    let hexScoresFinal = hexScores.map((hex) => applyFilter(hex));
    const maxScore = Math.max(...hexScoresFinal);
    hexScoresFinal = hexScoresFinal.map((hex) => rescale(hex, maxScore));
    setHexScoresFinal(hexScoresFinal);

    let hoodScoresFinal = hoodScores.map((hood) => applyFilter(hood));
    const maxScore2 = Math.max(...hoodScoresFinal);
    hoodScoresFinal = hoodScoresFinal.map((hood) => rescale(hood, maxScore2));
    setHoodScoresFinal(hoodScoresFinal);
  }, [hexScores, hoodScores, filters]);

  useEffect(() => {}, [hoodScores, filters]);

  return (
    <div className='w-screen h-screen flex flex-col'>
      <Header />
      <div className='w-full h-full flex flex-row'>
        <Sidebar layer={layer} setLayer={setLayer} setFilters={setFilters} />
        <Map
          layer={layer}
          hoodScores={hoodScoresFinal}
          hexScores={hexScoresFinal}
        />
      </div>
    </div>
  );
}

export default App;
