import React from "react";

const Header = () => {
  return (
    <div className='text-white bg-blue-500 shadow-xl body-font z-50'>
      <div className='p-5 flex flex-wrap'>
        <img src={""} alt='logo' className='w-6 h-6' />
        <span className='ml-2 text-xl'>Hopscotch</span>
      </div>
    </div>
  );
};

export default Header;
