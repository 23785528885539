import React, { useState, useCallback } from "react";

import Map, {
  Source,
  Layer,
  FullscreenControl,
  ScaleControl,
  NavigationControl,
} from "react-map-gl";

const MapSection = ({ layer, hexScores, hoodScores }) => {
  const [hoverInfo, setHoverInfo] = useState(null);

  const onHover = useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features && features[0];

    // prettier-ignore
    setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
  }, []);

  // For Tooltip
  const boroughName = hoverInfo?.feature?.properties?.BoroName;
  const neighborhoodName = hoverInfo?.feature?.properties?.NTAName;
  const score = Math.round(
    hoodScores[hoverInfo?.feature?.properties?.OBJECTID]
  );

  return (
    <Map
      reuseMaps
      initialViewState={{
        longitude: -74.0059,
        latitude: 40.7128,
        zoom: 9.25,
      }}
      minZoom={9}
      maxZoom={17}
      maxPitch={0}
      className='flex-grow'
      mapStyle='mapbox://styles/walz/cl773ucik000014og9wejvpsm' // "mapbox://stypes/mapbox/light-v10"
      interactiveLayerIds={["Neighborhoods"]}
      onMouseMove={onHover}
      mapboxAccessToken='pk.eyJ1Ijoic3RyYXRlZ29zIiwiYSI6ImNraHpjNnd0aDBva2UzM28yaDNhbDNrb3EifQ.ZJlpg4-LhOufF9CrLKuuWQ'
    >
      <Source
        id='Neighborhoods'
        type='vector'
        url='mapbox://strategos.3b1jfdsq'
      >
        <Layer
          id='Neighborhoods'
          source='Neighborhoods'
          type='fill'
          paint={{
            "fill-color": [
              "interpolate",
              ["linear"],
              ["at", ["get", "OBJECTID"], ["literal", hoodScores]],
              0,
              "#fff",
              100,
              "#00f",
            ],
            "fill-opacity": layer === "hood" ? 0.6 : 0,
            "fill-outline-color": "#000",
          }}
          {...{
            "source-layer": "neighborhoods-bmyici",
          }}
        />
      </Source>
      <Source id='Hexagons' type='vector' url='mapbox://strategos.4setx3e9'>
        <Layer
          id='Hexagons'
          source='Hexagons'
          type='fill'
          paint={{
            "fill-color": [
              "interpolate",
              ["linear"],
              ["at", ["get", "id"], ["literal", hexScores]],
              0,
              "#fff",
              100,
              "#00f",
            ],
            "fill-opacity": layer === "hex" ? 0.6 : 0,
            "fill-outline-color": [
              "step",
              ["zoom"],
              [
                "interpolate",
                ["linear"],
                ["at", ["get", "id"], ["literal", hexScores]],
                0,
                "#fff",
                100,
                "#00f",
              ],
              12,
              "#000",
            ],
          }}
          {...{
            "source-layer": "hexagons-5yjtm2",
          }}
        />
      </Source>
      {layer === "hood" && hoverInfo && (
        <div
          className='absolute z-10 py-2 px-3 text-sm text-white bg-gray-900 shadow-sm tooltip'
          style={{
            left: hoverInfo.x - 3.5 * neighborhoodName.length,
            top: hoverInfo.y - 80,
            zIndex: 10,
          }}
        >
          <div className='text-xs'>{boroughName}</div>
          <div>{neighborhoodName}</div>
          <div>{score}</div>
        </div>
      )}
      <FullscreenControl />
      <ScaleControl />
      <NavigationControl />
    </Map>
  );
};

export default MapSection;
