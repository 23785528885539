import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import ButtonGroup from "./form/ButtonGroup.js";
import RadioButton from "./form/Radio.js";

const Sidebar = ({ layer, setLayer, setFilters }) => {
  const [citiBikes, setCitiBikes] = useState(false);
  const [budget, setBudget] = useState(false);
  const [transit, setTransit] = useState(false);
  const [transit123, setTransit123] = useState(false);
  const [transit456, setTransit456] = useState(false);

  useEffect(() => {
    setFilters({
      citiBikes: citiBikes ? 1 : 0,
      budget: budget ? 1 : 0,
      transit: transit ? 1 : 0,
      transit123: transit123 ? 1 : 0,
      transit456: transit456 ? 1 : 0,
    });
  }, [citiBikes, budget, transit, transit123, transit456, setFilters]);

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const dealbreakers = ["Proximity to Work", "Budget"];
  const priorities = ["Proximity to Subway Lines", "Safety Score"];

  return (
    <div className='w-1/3 bg-blue-100 shadow-xl z-20 p-4'>
      <div className='text-xl'>Config</div>
      <div className='py-2'>
        <div className='my-2'>
          <ButtonGroup
            labels={["Neighborhoods", "Hexagons", "None"]}
            options={["hood", "hex", "none"]}
            checked={layer}
            setChecked={setLayer}
          />
        </div>
      </div>
      <div className='text-xl'>Demo Sample</div>
      <div className='flex flex-wrap w-full p-2'>
        <RadioButton
          label='Clear All'
          checked={false}
          setChecked={() => {
            setCitiBikes(false);
            setBudget(false);
            setTransit(false);
            setTransit123(false);
            setTransit456(false);
          }}
        />
        <RadioButton
          label='Citi Bikes'
          checked={citiBikes}
          setChecked={setCitiBikes}
        />
        <RadioButton label='Budget' checked={budget} setChecked={setBudget} />
        <RadioButton
          label='Transit'
          checked={transit}
          setChecked={setTransit}
        />
        <RadioButton
          label='Transit 123'
          checked={transit123}
          setChecked={setTransit123}
        />
        <RadioButton
          label='Transit 456'
          checked={transit456}
          setChecked={setTransit456}
        />
      </div>
      <div className='text-xl'>Dealbreakers</div>
      {dealbreakers.map((dealbreaker, index) => (
        <Accordion
          key={dealbreaker}
          open={open === index + 1}
          onClick={() => handleOpen(index + 1)}
          className='bg-gray-100 rounded px-4 m-2 w-auto'
          style={{
            base: {
              body: { "max-height": "32px" },
            },
          }}
        >
          <AccordionHeader className='text-sm'>{dealbreaker}</AccordionHeader>
          <AccordionBody>In progress!</AccordionBody>
        </Accordion>
      ))}
      <div className='text-xl'>Priorities</div>
      {priorities.map((priority, index) => (
        <Accordion
          key={priority}
          open={open === index + 100}
          onClick={() => handleOpen(index + 100)}
          className='bg-gray-100 rounded px-4 m-2 w-auto'
          style={{
            base: {
              body: { "max-height": "32px" },
            },
          }}
        >
          <AccordionHeader className='text-sm'>{priority}</AccordionHeader>
          <AccordionBody>In progress!</AccordionBody>
        </Accordion>
      ))}
    </div>
  );
};

export default Sidebar;
