import React from "react";

import { BsMapFill, BsHexagonFill, BsApp } from "react-icons/bs";
import { classnames } from "../../utils";

const Button = ({ label, checked, setChecked, leftmost, rightmost }) => {
  return (
    <button
      type='button'
      key={label}
      className={classnames(
        "inline-flex items-center py-2 px-2 text-xs font-medium text-gray-900 bg-white hover:bg-gray-100 hover:text-blue-700",
        checked
          ? "z-20 text-blue-700 ring-2 ring-blue-700"
          : "border border-gray-200",
        leftmost && "rounded-l-lg pl-4",
        rightmost && "rounded-r-lg pr-4"
      )}
      onClick={setChecked}
    >
      {label === "Neighborhoods" && (
        <BsMapFill className='text-blue-500 w-4 h-4 mr-1' />
      )}
      {label === "Hexagons" && (
        <BsHexagonFill className='text-blue-500 w-4 h-4 mr-1' />
      )}
      {label === "None" && <BsApp className='w-4 h-4 mr-1' />}
      {label}
    </button>
  );
};

const ButtonGroup = ({ labels, options, checked, setChecked }) => {
  return (
    <div className='inline-flex rounded-md shadow-sm' role='group'>
      <Button
        label={labels[0]}
        checked={checked === options[0]}
        setChecked={() => setChecked(options[0])}
        leftmost
      />
      <Button
        label={labels[1]}
        checked={checked === options[1]}
        setChecked={() => setChecked(options[1])}
      />
      <Button
        label={labels[2]}
        checked={checked === options[2]}
        setChecked={() => setChecked(options[2])}
        rightmost
      />
    </div>
  );
};

export default ButtonGroup;
